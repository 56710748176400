@use 'styles/functions' as fn;

.icon-list-item {
  list-style: none;
}

.icon-item {
  text-align: left;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: fn.spacing(1.5);
}

.icon-item-svg {
  margin-right: fn.spacing(1.5);
}

.icon-item p {
  display: contents;
}
